import React from 'react'
import Image from 'next/image'
import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Sitemap from '../../assets/Sitemap.png'
import timeline1 from '../../assets/timeline1.png'
import timeline2 from '../../assets/timeline2.png'
import timeline3 from '../../assets/timeline3.png'
import restaurant from '../../assets/restaurant.png'
import ui from '../../assets/ui.jpg'
import ui1 from '../../assets/ui1.jpg'
import plants from '../../assets/plants.jpg'
import bakery3 from '../../assets/bakery3.png'
import RE from '../../assets/RE.jpg'

const Projects = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <section id="projects" className="bg-gray-50 overflow-hidden py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="flex justify-center mt-2 text-5xl font-extrabold text-gray-700 ">Our Projects</h2>
        <br></br>

        <div className="flex justify-center"></div>
        <div className="Tabs">
          <div>

            <div className="nav flex justify-center">
              <TabNavItem title="UI" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} />
              <TabNavItem title="Social Media App" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />
              <TabNavItem title="Mindmap" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>

          <div className="outlet">
            <TabContent id="tab1" activeTab={activeTab}>
              <div className=" relative  py-20">
                <ul role="list" className="grid grid-cols-1 sm:grid-cols-1 gap-3  lg:grid-cols-3 ">
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={bakery3} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={RE} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={restaurant} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={ui1} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={ui} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={plants} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                </ul>
              </div>
            </TabContent>
            <TabContent id="tab2" activeTab={activeTab}>
              <div className=" relative  py-20">
                <ul role="list" className="grid grid-cols-1 sm:grid-cols-1 gap-3  lg:grid-cols-3 ">
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={timeline1} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={timeline2} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>
                  <li className="relative">
                    <div className="group block w-full aspect-square rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                      <Image src={timeline3} alt="" className="object-cover pointer-events-none group-hover:opacity-75" layout='responsive' />
                    </div>
                  </li>

                </ul>
                <blockquote className="mt-10">
                  <div className="max-w-3xl mx-auto text-center text-m font-medium text-gray-900">
                    <p>
                      &ldquo;   Creating a new app from a concept is no easy feat but working with someone who not only teaches you along the way but actually cares about the project is hard to find and working with Zadro, Juan specifically, entailed just that. It has been a fun adventure of creativity and inspiration. He brings expertise to the project and ideas we had not originally considered. He is a pleasure to work with and always has a smile on his face. I would recommend him to anyone who has a vision that needs execution and insight.&rdquo;
                    </p>
                  </div>
                  <footer className="mt-8 pb-24">
                    <div className="md:flex md:items-center md:justify-center">
                      <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                        <div className="text-base font-medium text-gray">Rebecca Wright</div>
                        <svg className="hidden md:block mx-1 h-5 w-5 text-gray" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M11 0h3L9 20H6l5-20z" />
                        </svg>
                        <div className="text-base font-medium text-gray">CEO, BCRA Incorporated</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </TabContent>
            <TabContent id="tab3" activeTab={activeTab}>
              <div className=" relative py-20">
                <Image src={Sitemap} alt="" className="object-contain pointer-events-none group-hover:opacity-75 aspect-video" layout='responsive' />
              </div>
            </TabContent>
          </div>
        </div>
      </div>
    </section>

  );
};

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {

  const handleClick = () => {
    setActiveTab(id);
  };
  // className={activeTab === id ? "active" : ""}
  return (
    <button onClick={handleClick} type="button" className="m-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium bg-orange-600 rounded-full hover:opacity-75 shadow-lg shadow-indigo-800/5">
      {title}
    </button>
  );
};

const TabContent = ({ id, activeTab, children }) => {
  return (
    activeTab === id ? <div className="TabContent">
      {children}
    </div>
      : null
  );
};

export default Projects
export async function getServerSideProps({ req, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  return {
    props: {},
  }
}


