import { useEffect } from 'react'
import Image from 'next/image'
import zadroLogoSmall from '../../assets/zadro-logo.svg'
import ZadroPhoneBG from '../../assets/zadro-phone.png'

function Splash() {
    return (
        <div className="splash-section">
            <div className="relative bg-zblue">
                <header className="header-section d-xl-block relative z-30">
                    <div className="">
                        <nav className="relative max-w-7xl mx-auto flex items-center justify-between">
                            <div className="flex items-center flex-1">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <a href="#">
                                        <span className="sr-only">Workflow</span>
                                        <Image src={zadroLogoSmall} alt="Zadro" width="273" height="113" />
                                    </a>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <button type="button" className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                                            <span className="sr-only">Open main menu</span>
                                            {/* <ZadroSmallLogo className="" /> */}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="hidden space-x-10 md:flex md:mr-10">
                                <a href="#start-up" className="text-base font-medium text-white hover:text-white">Our Services</a>
                                <a href="#about-zadro" className="text-base font-medium text-white hover:text-white">About Zadro</a>
                                <a href="#projects" className="text-base font-medium text-white hover:text-white">Projects</a>
                                {/* <a href="#faq" className="text-base font-medium text-white hover:text-white">FAQ</a> */}
                                <a href="#contact-us" className="text-base font-medium text-white hover:text-white">Contact Us</a>
                            </div>
                            <div className="hidden md:flex md:items-center py-2 mr-12">
                                <a href="/book" className="inline-flex items-center px-4 py-2 border border-transparent  text-base font-medium bg-orange-600 rounded-full hover:bg-indigo-800 shadow-lg shadow-blue-600/50">
                                    Book now
                                </a>
                            </div>
                        </nav>
                    </div>
                </header>
                <div className="relative max-w-7xl mx-auto py-10 md:pb-40 md:p-20 z-30">

                    <div className="mx-auto px-4 text-center lg:px-0 lg:flex lg:justify-center">
                        <div className="z-30">
                            <h1 className="mt-4 tracking-tight text-white sm:mt-5 text-3xl lg:mt-6 lg:text-7xl">


                                We transform ideas into real world products.
                                {/* is&nbsp;
                                <span className="text-block"></span></span> */}
                                {/* <span className=" text-indigo-50 sm:text-6xl xl:text-3xl">We wear it, Heavy!</span> */}
                            </h1>
                            <p className="text-white sm:mt-5 sm:text-lg lg:text-3xl">
                                {/* We create innovative products combining creativity, technical expertise, and mission driven development. */}

                            </p>
                            <br />
                            <a href="/book" className="inline-flex items-center px-4 py-2 border border-transparent text-base  font-medium bg-orange-600 rounded-full hover:bg-indigo-800 shadow-lg shadow-blue-600/50">
                                Let's talk  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 w-full h-full mix-blend-overlay">
                    <Image
                        src={ZadroPhoneBG}
                        alt="Zadro phone background | we are geeks at heart"
                        className="absolute inset-0 object-cover object-top w-full h-full"
                        layout="fill"
                    />
                </div>
            </div>
        </div>
    );
}

export default Splash