import React from 'react'
import Image from 'next/image'
import computerbg from '../../assets/computerbg.png'

const NewProduct = () => (
    <div>
      <aside className="relative overflow-hidden text-white bg-gradient-to-r from-sky-400 to-blue-700">
        <div className="relative z-10 max-w-screen-xl px-4 py-24 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-xl space-y-8 text-center sm:text-right sm:ml-auto">
            <h2 className="text-4xl font-bold sm:text-5xl">
              New product launched?
            </h2>
  
            <p className="sm:block sm:max-w-lg sm:ml-auto sm:text-lg">
              Your business will grow when
              <br />
              you have actionable data.
              {/* In fact the 90% mark, is as difficult as the last 10%
              <br />
              We have crossed the line mutiple times. */}
            </p>
            <p className="sm:block sm:max-w-lg sm:ml-auto sm:text-lg">
              Zadro will help you get there.
            </p>
  
  
            <a className="inline-flex items-center px-6 py-3 font-medium bg-orange-700 rounded-full hover:opacity-75" href="/book">
              Let's connect
  
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </a>
          </div>
        </div>
  
        <div className="absolute inset-0 w-full h-full mix-blend-multiply">
          <Image
            src={computerbg}
            alt="New robot toy"
            className="absolute inset-0 object-cover object-top w-full h-full"
            layout="fill"
          />
        </div>
      </aside>
    </div>
  )

export default NewProduct