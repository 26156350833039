
function PageHeader({ header, heading, imageUrl, content }) {
    return (
        <div className="splash-section z-30">
            <div className="relative bg-zblue">
                {header}
                <div className="relative max-w-7xl mx-auto py-10 md:pb-40 md:p-20 z-30">
                    <div className="container md px-4 text-center lg:px-0 lg:flex lg:justify-center">
                        <div className="">
                            <h1 className="mt-4 tracking-tight text-white sm:mt-5 text-3xl lg:mt-6 lg:text-7xl">
                                {heading}
                            </h1>
                            <br />

                            <p className=" text-white sm:mt-5 sm:text-lg lg:text-2xl">
                                {content}
                            </p>
                            <br />
                            <br />
                            <a href="https://calendly.com/zadrotech/30min" target="_blank" className="z-30 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium bg-orange-600 rounded-full hover:bg-indigo-800 shadow-lg shadow-blue-600/50">
                                Book now  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
                <div className="absolute inset-0 w-full h-full mix-blend-overlay">
                    <img
                        src={imageUrl}
                        alt="Zadro phone background | we are geeks at heart"
                        className="absolute inset-0 object-cover object-top w-full h-full"
                        layout="fill"
                    />
                </div>
            </div>
        </div>
    );
}

export default PageHeader