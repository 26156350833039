import React from 'react'
import Image from 'next/image';
import ZadroConsult from '../../assets/zgraph.svg'

const Services = () => {
    const features = [
      { name: 'Agile', description: `Scrum is our method of working. Work is divided into sprints, we improve continuously, and we adjust the project's scope as needed. As a result, we are able to maintain usability while controlling timelines and budget.` },
      { name: 'Fraction CTO', description: 'We offer the same expertise and capability as a full-time CTO without adding the associated level of overhead, salary, and benefits associated with adding a top-level executive for companies that otherwise could not afford or would not need one.' },
      { name: 'Mobile Applications', description: 'We build apps for iOS and Android devices using cross-platform alternatives. As part of our custom software development services, we ensure that the applications work smoothly across all devices.' },
      { name: 'Software Development', description: 'End-to-end custom Software development services for your business.', },
      { name: 'MVP Development', description: 'With our help, your idea will be broken down into pieces, reassembled, and presented as a product market fit.' },
      // { name: 'Custom Systems', description: 'Systems programming aims to produce software and software platforms which provide services to other software.'},
    ];
    return (
      <div>
        <div id="start-up" className="max-w-7xl mx-auto md:py-16 md:px-20">
          <div className="mt-12 px-5">
            <p className="mt-2 text-3xl lg:text-5xl font-extrabold text-gray-700">Startups are built from scratch.</p>
            <p className="mt-4 text-lg text-gray-500">
            Zadro can help you on a variety of different tasks.
            </p>
            <br>
            </br>
          </div>
  
          <div className="lg:mt-0 lg:columns-2">
            <div className="hidden lg:flex">
              <div className="transform -translate-x-40">
                <Image src={ZadroConsult} layout='fixed' alt="Zadro Ideas" />
              </div>
            </div>
            <div className="sm:grid sm:grid-flow-row ">
  
              {features.map((feature) => (
                <div key={feature.name} className="relative hover:object-top">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-6 w-6 text-orange-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                    <p className="ml-9 text-2xl leading-6 font-medium text-gray-700">{feature.name}</p>
                  </div>
                  <div className="mt-2 ml-9 mb-5 text-base text-gray-500">{feature.description}</div>
                </div>
              ))}
            </div>
          </div>
  
        </div>
        <div className="flex justify-evenly">
        </div>
      </div>
    )
  }

export default Services
export async function getServerSideProps({ req, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  return {
    props: {},
  }
}