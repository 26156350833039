import React from 'react'
import Image from 'next/image'
import juan from '../../assets/me.jpeg'
import maria from '../../assets/maria.jpeg'
import zadroLogo from '../../assets/zadroLogo.svg'

const AboutZadro = () => {
    // const people = [

    //   {
    //     name: 'Maria Cubeddu',
    //     role: 'Co-founder/Project Manager',
    //     imageUrl:
    //       maria.src,
    //     linkedinUrl: 'https://www.linkedin.com/in/maria-cubeddu-922b0b167/',
    //   },
    //   {
    //     name: 'Juan Cubeddu',
    //     role: 'Co-founder/CTO',
    //     imageUrl:
    //       juan.src,
    //     linkedinUrl: 'https://www.linkedin.com/in/juan-cubeddu-03423a149/',
    //   },
    //   // More people...
    // ]
  
    return (
      <div>
        <div id="about-zadro" className="bg-zblue container-entry">
          <div className="max-w-7xl mx-auto py-24 p-5 md:p-20 sm:px-6 lg:flex lg:justify-between lg:pt-40">
            <div className="flex flex-row ju">
              <div className="basis-1/2 hidden md:block">
                <Image src={zadroLogo} className=" object-scale-down" alt="Zadro" width="200" height="457" />
              </div>
              <div className="md:px-20">
                  <h2 className="text-3xl lg:text-6xl font-extrabold text-white ">About <span className="text-orange-600">Zadro</span></h2>
                  {/* <p className="pt-5 text-white">
                    We started simple: a developer, a designer, and a customer service expert who dares to think big and isn't afraid to be different.
                  </p> */}
                  <p className="pt-5 text-white">
                    We help deliver those milestones from prototypes all the way to product market fit and more. With over 25 years of experience.
                    We are an agile shop with a mission driven mindset, our goal is to help attain, guide or even mentor through your brand journey allowing you to focus on next phases rather than stuck in POC mode.
                  </p>
  

                  <br />
                  <p className="text-xxl font-extrabold text-white bg-gradient-to-tr from-white-400 to-zblue">We love exploring new technologies.</p>
                  <br />
  
                <p className="text-4xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-purple-900">We're geeks at <span className="heart-love" >❤️</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default AboutZadro
export async function getServerSideProps({ req, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  return {
    props: {},
  }
}