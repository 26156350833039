import Image from 'next/image'
import zadroLogoSmall from '../../assets/zadro-logo.svg'

export default function Header() {
  return (
    <header className="header-section d-xl-block relative">
      <div className="bg-zblue">
        <nav className="relative max-w-7xl mx-auto flex items-center justify-between z-30">
          <div className="flex items-center flex-1 ">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/#">
                <span className="sr-only">Workflow</span>
                <Image src={zadroLogoSmall} alt="Zadro" width="222" height="113" />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <button type="button" className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {/* <ZadroSmallLogo className="" /> */}
                </button>
              </div>
            </div>

          </div>
          <div className="hidden space-x-10 md:flex md:mr-10">
            <a href="/#start-up" className="text-base font-medium text-white hover:text-white">Our Services</a>
            <a href="/#about-zadro" className="text-base font-medium text-white hover:text-white">About Zadro</a>
            <a href="/#projects" className="text-base font-medium text-white hover:text-white">Projects</a>
            {/* <a href="#faq" className="text-base font-medium text-white hover:text-white">FAQ</a> */}
            <a href="/#contact-us" className="text-base font-medium text-white hover:text-white">Contact Us</a>
          </div>
        </nav>
      </div>
    </header>
  );
}
