import React from 'react'

const Faq = () => (
    <div id="faq" className="bg-zblue">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:max-w-2xl lg:mx-auto lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Frequently asked questions
          </h2>
          <p className="mt-4 text-gray-400">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus elementum blandit et.</p>
        </div>
        <div className="sm:grid grid-cols-2 lg:grid-cols-3 text-white">
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
          <div className="max-w-lg p-8">
            <details className=" p-6 rounded-lg">
              <summary className=" leading-6 font-semibold select-none">
                Why do they call it Ovaltine?
              </summary>
              <div className="mt-3 leading-6 text-gray-400">
                <p>The mug is round. The jar is round. They should call it Roundtine.</p>
              </div>
            </details>
          </div>
        </div>
      </div>
    </div>
  )

export default Faq